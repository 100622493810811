<template>
  <!-- 性能底盘,英文版 -->
  <div class="around chassis" v-if="elmentList.length">
    <div class="chassis-content">
      <div class="content-one" v-for="index in 3" :key="index"  :id="'elm' + elmentList[index - 1].id">
        <div :class="index === 1 ? 'content-one-img' : 'content-two-img'">
          <img :src="elmentList[index - 1].pcImg" v-if="$store.state.pageset.pcshow" />
          <img :src="elmentList[index - 1].phoneImg" v-else />
        </div>
        <div :class="index === 1 ? 'dec-two dec' : 'dec'" v-animate="'queue-bottom'">
          <div class="dec-left">
            <p class="p1">
              {{ elmentList[index - 1].descList[0].descName }}
            </p>
            <p class="p2">
              {{ elmentList[index - 1].descList[1].descName }}
            </p>
            <div class="scroll-wrap" v-if="index === 1">
              <div class="remark">
                The products appearing in the video on this page are for reference only. The appearance, configuration, model, and other details of the products may vary. Please refer to the actual local delivery for details.
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 左右排列参数 -->
      <div class="content-tree" v-if="this.$store.state.pageset.pcshow">
        <div v-for="index in 4" :key="index"  :id="'elm' + elmentList[index + 2].id">
          <div class="fun-item" v-if="index % 2 === 0">
            <div class="fun-left">
              <img :src="elmentList[index + 2].pcImg" />
            </div>
            <div class="fun-right">
              <div class="fun-right-dec" v-animate="'queue-bottom'">
                <p class="p1">
                  {{ elmentList[index + 2].descList[0].descName }}
                </p>
                <p class="p2">
                  {{ elmentList[index + 2].descList[1].descName }}
                </p>
              </div>
            </div>
          </div>
          <div class="fun-item" v-else>
            <div class="fun-left">
              <div class="fun-right-dec" v-animate="'queue-bottom'">
                <p class="p1">
                  {{ elmentList[index + 2].descList[0].descName }}
                </p>
                <p class="p2">
                  {{ elmentList[index + 2].descList[1].descName }}
                </p>
              </div>
            </div>
            <div class="fun-right">
              <img :src="elmentList[index + 2].pcImg" />
            </div>
          </div>
        </div>
      </div>
      <div class="content-tree" v-else>
        <div v-for="index in 4" :key="index">
          <div>
            <img :src="elmentList[index + 2].phoneImg" width="100%" height="100%" />
          </div>
          <div class="fun-dec">
            <div class="fun-right-dec" v-animate="'queue-bottom'">
              <p class="p1">{{ elmentList[index + 2].descList[0].descName }}</p>
              <p class="p2">{{ elmentList[index + 2].descList[1].descName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else v-loading="true"></div>
</template>
<script>
import chassis_en from "./chassis_en.js";

export default chassis_en;
</script>
<style scoped rel="stylesheet/stylus" lang="scss">
@import "~@/assets/style/common.scss";
@import "../../common.scss";
@import "../chassis.scss";
</style>
