
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcElementId: 170,
            //             phoneElementId: 177,
            //             descList: [
            //                 {
            //                     descName: 'Performance Chassis',
            //                     sort: 1
            //                 }, {
            //                     descName: 'The overall structure adopts modular design and assembly to achieve dynamic adjustment of the body control, making the entire machine have ultimate stability, support, and passability. High precision hanging is used to achieve high-frequency load reduction function. Not only can it ensure the stability of the image and accuracy of data during the detection process, but it can also more stably support the explosion-proof compartment, further improving inspection performance.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcElementId: 171,
            //             phoneElementId: 178,
            //             descList: [{
            //                 descName: 'Suspension System',
            //                 sort: 1
            //             }, {
            //                 descName: "Adopting 2X4 multi link independent suspension, it improves the driving stability of the product, solves high-frequency vibration, and can keep the wheels and ground as vertical as possible, reduce the tilting of the vehicle body, maintain the tire's ground adhesion, meet the driving needs of the product in various harsh environments, and have more efficient inspection capabilities.",
            //                 sort: 2
            //             }],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcElementId:172,
            //             phoneElementId: 179,
            //             descList: [
            //                 {
            //                     descName: '3rd Generation VCU',
            //                     sort: 1
            //                 }, {
            //                     descName: 'More types of hardware interfaces can meet the access needs of more types of sensors, and communication isolation and anti-interference hardware design can better improve the anti-interference ability of communication and ensure communication quality.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcElementId: 173,
            //             phoneElementId: 180,
            //             descList: [
            //                 {
            //                     descName: 'Blind Area Safety Bar',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Solve the problem of blind area of 45 °anti-collision bar on the side of the product.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcElementId: 174,
            //             phoneElementId: 181,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Heat Dissipation System',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Solve the internal high temperature problem of the product',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcElementId: 175,
            //             phoneElementId:182,
            //             descList: [
            //                 {
            //                     descName: 'Ground Clearance Adjustment System',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Overall ground clearance height',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 6
            //         },
            //         {
            //             pcElementId: 176,
            //             phoneElementId: 183,
            //             descList: [
            //                 {
            //                     descName: 'New Chassis Electronic Control System',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Meet the need for slope parking and reduce the distance of slope sliding',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 7
            //         }
            //     ],
            elmentList: [],
        }
    },
    created() {
        //           this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
        });
    },
    methods: {
        retention() {
            this.$router.push('/en/reservation');
        },
        openPdf() {
             this.$router.push('/pdfView')
        },

    }
}